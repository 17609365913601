import React from 'react'
import { SQL } from '@jelly/templates'

import Brother from '../../../images/lp/digital-copiers/BrotherLogo.png'
import Sharp from '../../../images/lp/digital-copiers/SharpLogo.png'
import Toshiba from '../../../images/lp/digital-copiers/ToshibaLogo.png'
import Xerox from '../../../images/lp/digital-copiers/XeroxLogo.png'

const DigitalCopiersMeta = {
	description: `Answer a few questions about your purchase, we'll match you with pre-screened sellers, and receive customized Digital Copier price quotes today.`,
	robots: `noindex, nofollow`,
	title: `Digital Copier Price Quotes from Qualified Sellers`,
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/compare-digital-copiers/`,
}

const HEADER_DATA = {
	bannerText: `Fast and free. Receive up to 5 quotes from Digital Copier brands by answering a few simple questions.`,
	taglineText: `SMART BUSINESS PURCHASES DELIVERED`,
	headerText: `Digital Copiers, Printers & Scanners`,
	captionText: `Share a few details about your office printer needs and get competing pricing quotes for free.`,
}

const FORM_DATA = {
	bodyOneTitleText: 'Start Here',
	subtext: 'For Your Free Quote',
	bodyTwoTitleText: 'Office Printers with Speeds & Sizes that Fit Your Needs',
	bodyText:
		'Multifunction printers and copiers allow you to print, copy, scan and fax. We work with brands who offer:',
	bullets: ['Laser printers', 'Copy machines', 'Printer scanners', 'Dual-tray printers', 'And more!'],
	widgetCategoryId: '10075',
	featureKey: 'pancakes-value-prop',
}

const PACKAGES_DATA = {
	packagesTitle: 'Multifunctional Printers and Copier Options',
	context: [
		{
			titleText: 'Best for Small Businesses',
			productName: 'Xerox VersaLink C405',
			bullets: ['Its smaller size suits most SMBS', 'Compatible with personal devices', 'Fully customizable UI'],
			img: `https://www.business.com/images/rz/5f46b4e37b4374fc5b8b4567/versalink-405.png`,
		},
		{
			titleText: 'Best Budget',
			productName: 'Brother MFC-J6545DW INKvestment Tank',
			bullets: ['Lowest-priced color copier', 'High resolution', 'Comes with a year of ink'],
			img: `https://www.business.com/images/rz/5f47cf109c658aae0e8b4567/brother-j6545dw.png`,
		},
		{
			titleText: 'Most Accessible',
			productName: 'Sharp MX-C304W',
			bullets: ['Multiple hands-free options', 'Adjustable touch keys', 'Optional Braille kit available'],
			img: `https://www.business.com/images/rz/5f47d5299c658aee328b4567/sharp-mxc304w.png`,
		},
	],
}

const BRAND_DATA = [{ img: Xerox }, { img: Brother }, { img: Sharp }, { img: Toshiba }]

const BUYING_GUIDE_DATA = [
	{
		headerText: 'Popular Features for Digital Copiers, Printers & Scanners',
		context: [
			{
				paragraph: `As with most business technology solutions, the features present in certain copiers or MFCs will likely make or break its usefulness to your company. After all, what may work for a design business may not work for an architectural firm. Modern copiers are rife with important features that not only increase their overall efficiency but bring some needed quality-of-life functionality that can help every employee deftly use the machine despite their level of capability.`,
			},
			{
				paragraph: `When deciding on a copy machine to buy or lease, keep the following factors in mind before taking the plunge.`,
			},
			{
				subHeader: 'Printing Speed',
			},
			{
				paragraph: `Speed counts when it comes to copiers and printers. No one wants to wait minutes on end for a printer to spit out a presentation or brochure. And for organizations that have a larger number of employees, a slow printer has a negative ripple effect on productivity across the office`,
			},
			{
				paragraph: `Speed is measured in pages per minute (ppm). In our reviews, we have listed the ppm rate of each printer. Many, if not most, copiers today have the same speeds for both black-and-white and color copies. Laser copiers generate a faster output than inkjet models.`,
			},
			{
				paragraph: `Many copiers offer flexible paper choices, allowing you to easily change paper sizes and feed the size of paper you need through the tray. A related feature that most businesses consider a must is automatic duplexing, where your color copier automatically copies (prints, scans or faxes) two-sided documents. This feature is a major time-saver. Without it, you'll be manually feeding pages into the machine, flipping them, inputting them back into the machine and repeating the process ad nauseam.`,
			},
			{
				subHeader: 'High-speed Printing',
			},
			{
				paragraph: `During our research, we found printing speeds that varied wildly from model to model. If your business needs lightning-fast speeds, look for a higher ppm value associated with the machine. According to our data, the fastest print speed we found was 85 ppm from the Toshiba e-Studio8515A.`,
			},
			{
				subHeader: 'Paper Capacity and Duty Cycle',
			},
			{
				paragraph: `We've also included the monthly duty cycle in each review. The duty cycle is simply the number of pages that your color copier prints each month. Monthly duty cycles may be as low as 10,000 pages or as high as 150,000-plus pages`,
			},
			{
				paragraph: `Think of the duty cycle as an indicator of a printer's endurance over time. And like speed, depending on the number of employees you have and projects you typically work on, you'll want to pay close attention to a printer's duty cycle when shopping for a copier.`,
			},
			{
				subHeader: 'Connectivity',
			},
			{
				paragraph: `In today's world of multiple devices and the cloud, the more options your digital copier offers for connections, the better.`,
			},
			{
				paragraph: `USB connection is still standard, but modern machines offer mobile connectivity and options for users to connect to their cloud drives, such as Box or Microsoft OneDrive. These options give users one-touch accessibility to print files that are stored anywhere, from almost any device.`,
			},
			{
				paragraph: `Wireless printing, allowing a user to print directly from their tablet or smartphone, is also important. Many copiers are equipped with the ability to scan directly to a cloud-based service. This can benefit businesses that receive a lot of paper documents and want to save them in an online drive or application for future reference or access.`,
			},
			{
				subHeader: 'Size and Weight',
			},
			{
				paragraph: `Though multifunction printers and copiers come in varying sizes and weights, most of them can be placed on a desktop. Many of the higher-end or enterprise-focused models can be significantly larger. A machine's size can also be affected by any additional add-ons and accessories added to the machine.`,
			},
			{
				paragraph: `Do not overlook the weight of a copier. How important is portability? For smaller copiers, weight is an important factor if you plan to place it on a desk or if it is meant to be portable.`,
			},
			{
				subHeader: 'Accessibility',
			},
			{
				paragraph: `To make the workplace more accessible to those with disabilities, many copier manufacturers have included features like embossed marks, motion sensors, touch screens with easy-to-use applications and voice control. Some copiers with voice control can link with Amazon's Alexa and allow users to use voice commands to complete print or copy jobs.`,
			},
			{
				subHeader: 'Security',
			},
			{
				paragraph: `In the age of the cloud and internet of things, security has become very important. Many copiers now come standard with password controls, centralized print policy controls and user-level access to restrict who can use the copier.`,
			},
			{
				paragraph: `Copiers are no longer viewed as a mundane office accessory; rather, they are now viewed as another endpoint vulnerable to the same threats as laptops and mobile phones.`,
			},
			{
				subHeader: `Copiers Are Offering More 'Green' Features`,
			},
			{
				paragraph: `Printer manufacturers have also been hard at work on designing products that are sustainable and environmentally friendly. Energy use (and costs) are an important factor for many business buyers. Some copiers come equipped with energy-saving modes and are made of recycled plastic. Many manufacturers offer recycling programs for toner cartridges and other accessories or supplies.`,
			},
		],
	},
	{
		headerText: 'Pricing Considerations for Digital Copiers, Printers & Scanners',
		context: [
			{
				paragraph: `When it comes to pricing out a copier for your office, you need to consider what features you need. Though most MFCs function the same, there are many product lines with varying degrees of functionality. That level of stratification within the copier market means that prices can vary wildly from one machine to another. It's also important to keep in mind other, ongoing costs, including extended service and warranty plans.`,
			},
			{
				paragraph: `Through our information-gathering efforts, we found models that cost as low as $300 for the more budget offerings, while the more high-end machines ran as high as $50,000. While the machines on the higher end had significantly more complex feature sets with a focus on serving niche businesses, many manufacturers offer a lease option to ease the sticker shock.`,
			},
			{
				subHeader: `Ink, Toner and Paper Will Add to Operating Costs`,
			},
			{
				paragraph: `It makes sense that the initial cost of a copier is what commands your attention, but you also need to consider the overall operating costs. As a business owner, you will need to maintain paper and ink supplies regardless of whether the device uses inkjet cartridges or toner.`,
			},
			{
				paragraph: `Replacement ink cartridges come with high price tags, so replacing them on a scale will cost you. Toner tends to last longer but is largely used for monochrome printing only. Some copiers have built-in ink reservoirs that manufacturers say reduce ink costs, since you only need to buy bottles of ink rather than replacement cartridges, and the ink lasts longer. Whether that's a feature you need will depend largely on how often you and your employees rely on the machine.`,
			},
			{
				subHeader: `Remember the Additional Costs`,
			},
			{
				paragraph: `Along with traditional operating costs associated with MFCs, consider the extra costs that come with operating such a machine. Sunk costs that come from poorly constructed or prohibitively slow machines take a toll on a business. It's for that reason that you want to avoid purchasing the cheapest machine; rather, go for the one that fits your budget and can provide the best functionality.`,
			},
			{
				paragraph: `Low-cost machines that seem inexpensive and appear like a benefit can cause problems later. Similarly, factors like copy speed can help or hinder worker productivity and collaboration from an operational perspective. Memory capacity is another factor, since higher memory means the machine will be faster at processing each job.`,
			},
			{
				paragraph: `It's just practical business sense to conduct a cost-benefit analysis before committing to a specific copier. For a copier that will serve as the workhorse for many employees, speed may be the top deciding factor. For design-centric businesses, such as ad agencies or architectural firms, ink cost and image quality are more important. Or perhaps a color copier won't fulfill your needs, in which case, you might need a wide-format printer.`,
			},
			{
				subHeader: `Contracts and Customer Service`,
			},
			{
				paragraph: `While you can buy a copier for your business outright, some models have rather large price tags. In those instances, it may be best to lease the machine and add its monthly cost as an operating expense. Known as "managed print" in the printing world, leasing has become a more popular option, since newer models with more advanced features frequently enter the market, pushing even modern machines closer to obsolescence.`,
			},
			{
				paragraph: `Capital leases allow you to claim the machine a business asset, and operating leases permit you to mark the machine as an operating expense. Managed print services generally bundle things like ink or toner, paper and maintenance, into a monthly cost. Most leasing agreements last between three and five years, which is just enough time to consider upgrading to a new machine if you need to.`,
			},
			{
				paragraph: `While leasing may be an attractive option, it comes with some downsides. You may not need to make a large down payment on the copier, but you will pay interest on it, which increases your overall costs.`,
			},
			{
				paragraph: `Conversely, you can keep the copier for as long as you want if you buy it outright, potentially resulting in an overall savings, if you own it for a long time and don't feel the need to upgrade every few years. Buying outright also means you're not tied down to a multiyear contract, allowing you some more flexibility in your decision-making.`,
			},
			{
				paragraph: `The decision to purchase or lease a copier depends on your cash flow situation and which option benefits you the most in terms of maintenance and service, features, accessories, and supplies.`,
			},
			{
				subHeader: `Repair Costs`,
			},
			{
				paragraph: `Depending on how you obtain the machine, you may not have to worry about repair and maintenance costs at all. Most leases include some regular maintenance within their terms. In some cases, however, an additional extended warranty or service agreement will be offered for an added cost. What's covered under those plans can vary from one manufacturer to another, so you may be left holding the bag for any repair costs that fall outside of the agreement's terms. `,
			},
		],
	},
]

const DigitalCopiers = () => {
	return (
		<>
			<SQL
				meta={DigitalCopiersMeta}
				headerData={HEADER_DATA}
				formData={FORM_DATA}
				packagesData={PACKAGES_DATA}
				brandData={BRAND_DATA}
				buyingGuideData={BUYING_GUIDE_DATA}
			/>
		</>
	)
}

export default DigitalCopiers
